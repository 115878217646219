.valuesBackground {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  overflow: hidden;
}

.container {
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.container h1 {
  font-size: 40px;
}

.valuesList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 100px;
  margin-bottom: 20px;
  gap: 10px;
}

.value {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 10vh;
  background-color: rgba(255, 255, 255, 0.474);
  border-radius: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 4vw;
  transition: background 0.5s ease, font-size 0.5s ease, width 0.5s ease;

}

.valueActive {
  cursor: pointer;
  display: flex;
  min-width: 100%;
  height: 10vh;
  background-color: rgb(37, 37, 37);
  border-radius: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 4.5vw;
  transition: background 0.5s ease, font-size 0.5s ease, width 0.5s ease;
}

@media (min-width: 700px) {
  .value {
    font-size: 2vw;
    width: 90%;
  }

  .valueActive {
    width: 95%;
    font-size: 2vw;
  }

}

.value:hover {
  background-color: rgb(73, 73, 73);
}

.description {
  font-size: 4vw;
  min-width: 100%;
  background-color: rgb(66, 66, 66);
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  color: rgb(255, 255, 255);
}


@media (min-width: 700px) {

  .description {
    font-size: 1.5vw;
    min-width: 90%;
  }
}

.description p {
  padding: 0px 15px 0px 15px;
}

.descriptionVisible {
  max-height: 300px;
  /* Ajuste conforme o tamanho da descrição */
  opacity: 1;
}