/* Header.css */

@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: sticky;
    top: 0;
    height: 5vh;
    padding: 20px;
    background-color: transparent;
    background-color: white;
    transition: background-color 0.2s ease;
    /* Transição suave */
    box-shadow: none;
    z-index: 999;
}

.header.scrolled {
    background-color: rgba(0, 0, 0, 0.7);
    /* Fundo semi-transparente ao scrollar */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Adiciona sombra ao scrollar */

}

.logo {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Sans-serif', Arial, sans-serif;
}


.navbar ul {
    cursor: pointer;
    list-style: none;
    display: flex;
    gap: 40px;
    margin: 0;
    padding: 0;
    user-select: none;
}

.navbar li {
    font-family: 'Serif', Times, serif;
    text-decoration: none;
    font-size: 20px;
    user-select: none;
}

@media (max-width: 500px) {
    .navbar li {
        font-size: 15px;
    }

    .navbar ul {
        gap: 10px;
    }
}

.navbar li a {
    position: relative;
    color: black;
    text-decoration: none;
    font-family: "Arvo", serif;
    font-weight: 500;
    font-style: normal;
    user-select: none;
}

.navbar.scrolled li a {
    position: relative;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-family: "Arvo", serif;
    font-weight: 500;
    font-style: normal;
}

.navbar li a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    background-color: black;
    height: 1px;
    width: 0;
    transition: width 0.4s ease-in, left 0.4s ease;
}

.navbar.scrolled li a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    background-color: rgb(255, 255, 255);
    height: 1px;
    width: 0;
    transition: width 0.4s ease-in, left 0.4s ease;
}



.navbar li a:hover::after {
    width: 100%;
    left: 0;
}