@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.container {
    height: auto;
    padding: 20px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: end;
    text-align: center;
}

.values {
    width: 50%;
}

.container h1 {
    font-family: 'Sans-serif';
    font-size: 40px;
    /* Tamanho da fonte menor em telas grandes */
    margin-bottom: 20px;
}

/* Estilo principal do Founder */
.founder {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: left;
    width: 100%;
}

.box {
    position: absolute;
    width: 50%;
    left: 0;
    top: 0
}

@media (max-width: 768px) {
    .founder {
        flex-direction: column;
        text-align: center;
    }
}

/* Imagem do Founder */
.founder img {
    border-radius: 50%;
    /* Deixa a imagem circular */
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
}

/* Ajuste de tamanho de imagem para telas menores */
@media (max-width: 430px) {
    .founder img {
        width: 150px;
        height: 150px;
    }

    .values,
    .box {
        width: 100%;
    }

    .values {
        margin-top: 65vh;
    }

    .container {
        flex-direction: column;
    }
}

/* Título e descrição do Founder */
.founder h3 {
    font-family: 'Sans-serif';
    font-size: 4vw;
    /* Menor em telas grandes */
    margin: 10px 0;
    text-align: center;
}

@media (min-width: 1200px) {
    .founder h3 {
        font-size: 3vw;
        /* Ainda menor para telas muito grandes */
    }
}

.founder p {
    font-size: 2.5vw;
    /* Tamanho menor para telas maiores */
    font-family: "Playfair Display", serif;
    font-weight: 500;
    line-height: 1.4;
}

@media (min-width: 1200px) {
    .founder p {
        font-size: 1.5vw;
        /* Ajuste menor em telas maiores */
    }
}

/* Para telas pequenas, ajusta o tamanho */
@media (max-width: 430px) {
    .founder h3 {
        font-size: 5vw;
    }

    .founder p {
        font-size: 4vw;
    }
}

.graduation {
    width: 100%;
    text-align: center;
    padding: 0 10px;
}

@media (max-width: 768px) {
    .graduation {
        width: 90%;
        text-align: center;
    }
}